import React from 'react';
import mixpanel from 'mixpanel-browser';
import { AuthProvider, MixpanelContext } from './src/context';
import { UserProgressContextProvider } from './src/context/UserProgressContext';
import './src/styles/global.css';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/master`,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
  },
});

if (process.env.NODE_ENV !== 'production') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export const wrapRootElement = ({ element }) => {
  mixpanel.init('ab6153ea891a82ae2ef10839712f57b1');

  return (
    <AuthProvider>
      <MixpanelContext.Provider value={mixpanel}>
        <UserProgressContextProvider>
          <ApolloProvider client={client}>{element}</ApolloProvider>
        </UserProgressContextProvider>
      </MixpanelContext.Provider>
    </AuthProvider>
  );
};

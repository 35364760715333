import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

interface UserProfile {
  givenName: string;
  surname: string;
  mail: string;
  officeLocation: string;
  id: string;
  isFallback?: boolean;
}

const isSSOEnabled = process.env.GATSBY_SSO === 'enabled';

export function useProfile() {
  const fallbackProfile = {
    givenName: 'User',
    surname: 'Name',
    mail: '',
    officeLocation: '',
    id: '555',
    isFallback: true,
  };

  const { instance, accounts, inProgress } = useMsal();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // console.log('SSO Status:', isSSOEnabled);
    // console.log('MSAL Status:', { inProgress, hasAccounts: accounts.length > 0 });

    // If SSO is disabled, use fallback immediately
    if (!isSSOEnabled) {
      console.log('Using fallback profile for local development');
      setProfile(fallbackProfile);
      setLoading(false);
      return;
    }

    // Don't proceed if MSAL is still initializing
    if (inProgress !== 'none') {
      console.log('MSAL still initializing, waiting...');
      return;
    }

    async function getProfile() {
      try {
        if (!accounts[0]) {
          console.log('No authenticated account found, using fallback');
          setProfile(fallbackProfile);
          return;
        }

        // Ensure instance is available
        if (!instance) {
          throw new Error('MSAL instance not available');
        }

        const response = await instance
          .acquireTokenSilent({
            scopes: ['User.Read'],
            account: accounts[0],
          })
          .catch(async error => {
            console.log('Token acquisition failed:', error);
            if (error.name === 'InteractionRequiredAuthError') {
              return await instance.acquireTokenPopup({
                scopes: ['User.Read'],
              });
            }
            throw error;
          });

        const result = await fetch('https://graph.microsoft.com/v1.0/me', {
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        });

        if (!result.ok) {
          throw new Error(
            `Graph API error: ${result.status} ${result.statusText}`,
          );
        }

        const data = await result.json();
        // console.log('Microsoft Graph API response:', data);

        setProfile({
          givenName: data.givenName || '',
          surname: data.surname || '',
          mail: data.mail || '',
          officeLocation: data.officeLocation || '',
          id: data.id,
          isFallback: false,
        });
      } catch (err) {
        console.error('Profile fetch error:', err);
        setError(err instanceof Error ? err.message : 'Unknown error occurred');
        console.log('Using fallback profile due to error');
        setProfile(fallbackProfile);
      } finally {
        setLoading(false);
      }
    }

    getProfile();
  }, [instance, accounts, inProgress]);

  // Debug log to show current profile state
  // console.log('Current profile state:', {
  //   profile,
  //   loading,
  //   error,
  //   isSSO: isSSOEnabled,
  //   isFallback: profile?.isFallback || false
  // });

  return {
    profile,
    loading,
    error,
    isSSO: isSSOEnabled,
    isFallback: profile?.isFallback || false,
  };
}

import React, { useState, useEffect } from 'react';
import { Slide } from 'react-toastify';
import Confetti from 'react-dom-confetti';
import { Link } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES } from '@contentful/rich-text-types';
import { useToastCmsData } from '../hooks/useToastCmsData';

const richTextOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node: any, children: React.ReactNode) => {
      return (
        <a
          href={node.data.uri}
          className="text-core-neutral-white font-serif underline"
          target="_blank"
        >
          {children}
        </a>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node: any, children: React.ReactNode) => {
      return (
        <Link
          to={node.data.target.slug}
          target="_blank"
          className="text-core-neutral-white font-serif underline"
        >
          {children}
        </Link>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node: any, children: React.ReactNode) => {
      return (
        <a
          href={node.data.target.file.url}
          className="text-core-neutral-white font-serif underline"
          target="_blank"
        >
          {children}
        </a>
      );
    },
  },
};

// define the toast options to pass where the toast() is called
// this passes options and styles on the outer toast container
interface ToastOptions {
  toastId: string;
  transition: typeof Slide;
  hideProgressBar: boolean;
  position: 'bottom-left';
  className: string;
}

type ToastStyle = 'celebratory' | 'onboarding' | 'custom' | 'default';

export const toastOptions = (toastStyle: ToastStyle): ToastOptions => {
  switch (toastStyle) {
    case 'celebratory':
      return {
        toastId: 'celebratory',
        transition: Slide,
        hideProgressBar: true,
        position: 'bottom-left',
        className:
          'bg-freestyle-yellow px-[24px] py-[24px] rounded-[16px]  w-full md:w-[418px]  shadow-[0px_10px_10px_-5px_rgba(0,0,0,0.10)]',
      };
    case 'onboarding':
      return {
        toastId: 'onboarding',
        transition: Slide,
        hideProgressBar: true,
        position: 'bottom-left',
        className:
          'bg-core-medium-blue px-[24px] py-[24px] rounded-[16px] w-full md:w-[418px] shadow-[0px_10px_10px_-5px_rgba(0,0,0,0.10)]',
      };
    case 'custom':
      return {
        toastId: 'custom',
        transition: Slide,
        hideProgressBar: true,
        position: 'bottom-left',
        className:
          'bg-core-medium-blue px-[24px] py-[24px] rounded-[16px] w-full md:w-[418px]  shadow-[0px_10px_10px_-5px_rgba(0,0,0,0.10)]',
      };
    default:
      return {
        toastId: 'default',
        transition: Slide,
        hideProgressBar: true,
        position: 'bottom-left',
        className:
          'bg-neutral-black px-[24px] py-[24px] rounded-[16px]   w-full md:w-[418px]  shadow-[0px_10px_10px_-5px_rgba(0,0,0,0.10)]',
      };
  }
};

// Config for confetti styles
const confettiConfig = {
  angle: '90',
  spread: '190',
  startVelocity: '21',
  elementCount: '52',
  dragFriction: 0.12,
  duration: 5000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

// define the custom toast inner component with styles
interface CustomToastProps {
  closeToast: () => void;
  toastType: string;
}

export const CustomToast = ({
  closeToast,
  toastType,
  userName,
}: CustomToastProps) => {
  // Get users name for text replacement
  // const { profile, loading, error, isSSO } = useProfile();
  // const getUserName = () => {
  //   if (!isSSO) return 'Test User';
  //   if (loading) return '--';
  //   if (error || !profile) return 'Test User';
  //   return `${profile.givenName}`;
  // };
  // const userFirstName = getUserName();
  // const [userName, setUserName] = useState(userFirstName);

  // useEffect(() => {
  //   setUserName(profile?.givenName || 'Test User');
  // }, [profile?.givenName]);

  // Get CMS data
  const cmsData = useToastCmsData();

  // Format CMS data with user name
  // Celebratory toast
  const celebratoryCmsData = cmsData.find(
    toast => toast.toastType === 'celebratory',
  );
  const celebratoryTitle = celebratoryCmsData.toastContent.title.replace(
    /{userName}/gi,
    userName,
  );
  const celebratoryRAW = celebratoryCmsData.toastContent.body.raw.replace(
    /{userName}/gi,
    userName,
  );
  celebratoryCmsData.toastContent.body.raw = celebratoryRAW;
  const celebratoryBody = celebratoryCmsData.toastContent.body;
  // Onboarding toast
  const onboardingCmsData = cmsData.find(
    toast => toast.toastType === 'onboarding',
  );
  const onboardingTitle = onboardingCmsData.toastContent.title.replace(
    /{userName}/gi,
    userName,
  );
  const onboardingRAW = onboardingCmsData.toastContent.body.raw.replace(
    /{userName}/gi,
    userName,
  );
  onboardingCmsData.toastContent.body.raw = onboardingRAW;
  const onboardingBody = onboardingCmsData.toastContent.body;
  // Custom toast (if it exists)
  const customCmsData = cmsData.find(toast => toast.toastType === 'custom');
  let customTitle;
  let customRAW;
  let customBody;

  if (customCmsData.toastContent !== null) {
    customTitle = customCmsData.toastContent.title.replace(
      /{userName}/gi,
      userName,
    );
    customRAW = customCmsData.toastContent.body.raw.replace(
      /{userName}/gi,
      userName,
    );
    customCmsData.toastContent.body.raw = customRAW;
    customBody = customCmsData.toastContent.body;
  }

  const [throwConfetti, setThrowConfetti] = useState(false);
  useEffect(() => {
    setThrowConfetti(true);
  }, []);

  // Return the toast based on the type
  switch (toastType) {
    case 'celebratory':
      // Celebratory completed task toast
      return (
        <>
          <div className="flex w-full justify-between">
            <div className="mr-[16px]  flex-col">
              <div className="mb-1 flex items-center">
                <div className="h-[25px] w-[25px] flex-shrink-0 text-neutral-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    strokeWidth={1}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>

                <div className=" ml-1 font-sans font-[20px] font-bold uppercase text-neutral-black">
                  {celebratoryTitle}
                </div>
              </div>
              <div className="[&_p]:font-serif [&_p]:text-base [&_p]:leading-[25px] [&_p]:text-neutral-black">
                {renderRichText(celebratoryBody, richTextOptions)}
              </div>
            </div>
            <button
              onClick={closeToast}
              className="flex h-[36px] w-[36px] flex-shrink-0 items-center justify-center rounded-full bg-neutral-black text-neutral-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <Confetti active={throwConfetti} config={confettiConfig} />
        </>
      );
    case 'onboarding':
      // First Use Onboarding toast
      return (
        <div className="flex w-full justify-between">
          <div className="mr-[16px] flex-col">
            <div className="mb-1 font-sans font-[20px] font-bold uppercase text-neutral-white">
              {onboardingTitle}
            </div>
            <div className="[&_p]:font-serif [&_p]:text-base [&_p]:leading-[25px] [&_p]:text-neutral-white">
              {renderRichText(onboardingBody, richTextOptions)}
            </div>
          </div>
          <button
            onClick={closeToast}
            className=" flex h-[36px] w-[36px] flex-shrink-0 items-center justify-center rounded-full bg-neutral-light-grey-02 text-neutral-charcoal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      );

    case 'custom':
      // Custom message toast
      return (
        <div className="flex w-full justify-between">
          <div className="mr-[16px]  flex-col">
            <div className="mb-1 font-sans font-[20px] font-bold uppercase text-neutral-white">
              {customTitle}
            </div>
            <div className="[&_p]:font-serif [&_p]:text-base [&_p]:leading-[25px] [&_p]:text-neutral-white">
              {renderRichText(customBody, richTextOptions)}
            </div>
          </div>
          <button
            onClick={closeToast}
            className=" flex h-[36px] w-[36px] flex-shrink-0 items-center justify-center rounded-full bg-neutral-light-grey-02 text-neutral-charcoal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      );
  }
};

// add flow step dependancy

import { useStaticQuery, graphql } from 'gatsby';

interface ChecklistItem {
  contentful_id: string;
  contentfulName: string;
  primaryText: string;
}

interface PageInfo {
  contentfulName: string;
  slug: string;
}

interface ChecklistNode {
  layout: 'SimpleChecklist' | 'ComplexChecklist';
  contentful_id: string;
  contentfulName: string;
  primaryText: string;
  page: PageInfo[];
  checklistItems: ChecklistItem[];
}

interface ChecklistQueryResponse {
  allContentfulPageSection: {
    nodes: ChecklistNode[];
  };
}

// Get all checklist entries from the CMS to be used in the checklist context
// Filter to include both simple and complex checklists
// Filter to include only English language content otherwise we get duplicates for the empty FR entries
// Filter to include only checklists that are associated with a page (regex)
export const useChecklistCmsData = (): ChecklistNode[] => {
  const response = useStaticQuery<ChecklistQueryResponse>(graphql`
    query AllContentfulPageSection {
      allContentfulPageSection(
        filter: {
          node_locale: { eq: "en-US" }
          layout: { in: ["SimpleChecklist", "ComplexChecklist"] }
          page: { elemMatch: { slug: { regex: "" } } }
        }
      ) {
        nodes {
          layout
          contentful_id
          contentfulName
          primaryText
          page {
            contentfulName
            slug
          }
          checklistItems {
            contentful_id
            contentfulName
            primaryText
          }
        }
      }
    }
  `);
  return response.allContentfulPageSection.nodes;
};

import { useStaticQuery, graphql } from 'gatsby';

export const useToastCmsData = () => {
  const response = useStaticQuery(graphql`
    query AllContentfulToastContainers {
      allContentfulToastContainer {
        nodes {
          contentfulName
          expiresOn
          toastType
          toastContent {
            ... on ContentfulToast {
              title
              contentfulName
              body {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    __typename
                    file {
                      url
                    }
                  }
                  ... on ContentfulPage {
                    contentful_id
                    __typename
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return response.allContentfulToastContainer.nodes;
};

// Check to see that the toast content is not null and that the expiry date has not passed
export const isExpired = () => {
  const cmsData = useToastCmsData();
  const customCmsData = cmsData.find(
    toastContainer => toastContainer.toastType === 'custom',
  );
  if (customCmsData.toastContent === null) {
    return true;
  } else {
    const currentDate = new Date();
    const expiryDate = new Date(customCmsData.expiresOn);
    const isExpired = currentDate >= expiryDate;
    return isExpired;
  }
};

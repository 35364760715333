import React, { useEffect, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const config: Configuration = {
  auth: {
    clientId: 'd81d659c-4d08-49aa-9cb1-38ba2136fca5',
    authority: 'https://login.microsoftonline.com/5b268d57-2a6f-4e04-b0de-6938583d5ebc',
    redirectUri: 'https://aop-dev.twqa.ca/auth-response',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read']
};

const pca = new PublicClientApplication(config);

// Initialize MSAL
pca.initialize().catch(error => {
  console.error('MSAL initialization failed:', error);
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    pca.initialize()
      .then(() => {
        console.log('MSAL initialized successfully');
        setIsInitialized(true);
      })
      .catch(error => {
        console.error('MSAL initialization failed:', error);
        setIsInitialized(true); // Still set to true so app can proceed with fallback
      });
  }, []);

  // Show loading state while MSAL initializes
  if (!isInitialized) {
    return <div>Loading authentication...</div>;
  }

  return <MsalProvider instance={pca}>{children}</MsalProvider>;
}

import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { useChecklistCmsData } from '../hooks/useChecklistCmsData';
import { useProfile } from '../hooks/useProfile';
import { sha256 } from 'js-sha256';
import { toast } from 'react-toastify';
import { CustomToast, toastOptions } from '../components/Toast';
import { onboardingStepCount } from '../hooks/useFuoData';
import { isExpired } from '../hooks/useToastCmsData';

interface ChecklistItem {
  contentful_id: string;
  isCompleted: boolean;
  [key: string]: any;
}

interface Checklist {
  contentful_id: string;
  isCompleted: boolean;
  celebratoryStatusAchieved: boolean;
  checklistItems: ChecklistItem[];
  [key: string]: any;
}

interface UserProgressState {
  userId: string;
  flowStep: number;
  checklists: Checklist[];
}

interface DBChecklist {
  id: string;
  isComplete: boolean;
  celebratoryStatusAchieved: boolean;
  name: string;
  items: {
    id: string;
    isComplete: boolean;
    name: string;
  }[];
}

interface UserProgressContextType {
  userProgressState: UserProgressState;
  isInitialized: boolean;
  updateUserFlowStep: (newFlowStep: number) => void;
  updateUserChecklistProgress: (
    itemStatus: boolean,
    checkboxID: string,
    checklistID: string,
  ) => void;
}

// Create context to hold user progress data
export const UserProgressContext = createContext<
  UserProgressContextType | undefined
>(undefined);

// Create context provider to wrap around the site
export const UserProgressContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // Get user profile data and loading state from SSO
  const { profile, loading } = useProfile();

  // Keep track of latest profile to avoid closure issues
  const profileRef = React.useRef(profile);
  useEffect(() => {
    profileRef.current = profile;
  }, [profile]);

  // Get all checklists from the CMS that are attached to pages
  const checklistCmsData = useChecklistCmsData();

  // Create temp user progress object to hold checklist and other user data
  const tempUserProgressObject: UserProgressState = {
    userId: '', // Initialize empty, will be set when profile is loaded
    flowStep: -1,
    checklists: checklistCmsData,
  };

  // Get total # of first use onboarding steps to determine toast display
  const onboardingSteps = onboardingStepCount();

  // Check if custom message toast is expired
  const isToastExpired = isExpired();

  // Put the temp user progress object in state
  const [userProgressState, setUserProgressState] = useState<UserProgressState>(
    tempUserProgressObject,
  );

  // Track if we've initialized with profile data
  const [isInitialized, setIsInitialized] = useState(false);

  // Update userProgressState when profile (SSO connection) changes and fetch data
  useEffect(() => {
    if (loading) return;
    if (!profile?.id) return;

    const hashedUserId = sha256(profile.id);
    // console.log('Profile update started:', {
    //   id: profile.id,
    //   hashed: hashedUserId,
    //   isFallback,
    // });

    // First update the state with the correct ID
    setUserProgressState((current: UserProgressState) => {
      const newState = {
        ...current,
        userId: hashedUserId,
      };
      // console.log('Setting initial state with ID:', {
      //   id: hashedUserId,
      //   isFallback,
      //   state: newState,
      // });
      return newState;
    });
    setIsInitialized(true);

    // Then fetch data from DB
    async function fetchData() {
      try {
        // console.log('Fetching data with ID:', hashedUserId);
        const response = await fetch(
          `https://aop-functionapp-dev.azurewebsites.net/api/aop-function-dev?userId=${hashedUserId}`,
        );
        const data = await response.json();
        // If there is not yet a matching user in the DB
        if (data.length === 0) {
          // Create a new user object
          setUserProgressState((current: UserProgressState) => {
            const newUserProgressObject = {
              ...current,
              userId: hashedUserId, // Ensure we keep the correct ID
            };
            // Initialize checklist completion states
            newUserProgressObject.checklists.forEach((checklist: Checklist) => {
              checklist.isCompleted = false;
              checklist.celebratoryStatusAchieved = false;
              checklist.checklistItems.forEach(
                (checklistItem: ChecklistItem) => {
                  checklistItem.isCompleted = false;
                },
              );
            });
            // console.log({ newUserProgressObject });
            // Post to DB after state is set
            postData(newUserProgressObject);
            return newUserProgressObject;
          });
          // If there is a matching user in the DB
        } else if (data.length > 0) {
          setUserProgressState((current: UserProgressState) => {
            const updatedUserProgressObject = {
              ...current,
              userId: hashedUserId, // Ensure we keep the correct ID
              flowStep: data[0].flowStep,
            };
            // set flow step to session storage for later toast use, as useProgressObject isn't updating in time
            window.sessionStorage.setItem('userFlowStep', data[0].flowStep);
            // Update checklist states from DB into user state
            updatedUserProgressObject.checklists.forEach(
              (checklist: Checklist) => {
                // Find the matching checklist in the DB data and get their status
                const dbChecklistCompleted = data[0].checklists.find(
                  (dbChecklist: DBChecklist) =>
                    dbChecklist.id === checklist.contentful_id,
                );
                checklist.isCompleted = dbChecklistCompleted
                  ? dbChecklistCompleted.isComplete
                  : false;
                checklist.celebratoryStatusAchieved = dbChecklistCompleted
                  ? dbChecklistCompleted.celebratoryStatusAchieved
                  : false;
                checklist.checklistItems.forEach(
                  (checklistItem: ChecklistItem) => {
                    const dbChecklistItemCompleted =
                      dbChecklistCompleted?.items.find(
                        (dbChecklistItem: DBChecklist['items'][0]) =>
                          dbChecklistItem.id === checklistItem.contentful_id,
                      );
                    checklistItem.isCompleted = dbChecklistItemCompleted
                      ? dbChecklistItemCompleted.isComplete
                      : false;
                  },
                );
              },
            );
            return updatedUserProgressObject;
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData()
      .then(() => {
        // If user is only partway through onboarding, display the toast
        const userFlowStep = Number(
          window.sessionStorage.getItem('userFlowStep'),
        );
        const onbaordingToastDisplayed = window.sessionStorage.getItem(
          'onboardingToastDisplayed',
        );
        if (
          !onbaordingToastDisplayed &&
          userFlowStep &&
          userFlowStep > -1 &&
          userFlowStep < onboardingSteps - 2 &&
          profile.givenName &&
          profile.id !== '555'
        ) {
          toast(
            <CustomToast
              toastType={'onboarding'}
              userName={profile.givenName}
            />,
            toastOptions('onboarding'),
          );
          window.sessionStorage.setItem('onboardingToastDisplayed', 'true');
        }
      })
      .then(() => {
        // Call custom message toast on mount and if not expired, display it
        const customToastDisplayed = window.sessionStorage.getItem(
          'customToastDisplayed',
        );
        if (
          !customToastDisplayed &&
          !isToastExpired &&
          profile.givenName &&
          profile.id !== '555'
        ) {
          toast(
            <CustomToast toastType={'custom'} userName={profile.givenName} />,
            toastOptions('custom'),
          );
          window.sessionStorage.setItem('customToastDisplayed', 'true');
        }
      });
  }, [profile, loading]);

  // Post user progress data to DB
  async function postData(updatedUserProgressObject: UserProgressState) {
    // console.log('Profile data before post:', {
    //   userId: updatedUserProgressObject.userId,
    //   updatedUserProgressObject: updatedUserProgressObject,
    // });

    // Reshape the object to match the DB schema
    const reshapedUserProgressObject = {
      userId: updatedUserProgressObject.userId,
      flowStep: updatedUserProgressObject.flowStep,
      checklists: updatedUserProgressObject.checklists.map(
        (checklist: Checklist) => ({
          id: checklist.contentful_id,
          isComplete: checklist.isCompleted,
          celebratoryStatusAchieved: checklist.celebratoryStatusAchieved,
          name: checklist.contentfulName,
          items: checklist.checklistItems.map(
            (checklistItem: ChecklistItem) => ({
              id: checklistItem.contentful_id,
              isComplete: checklistItem.isCompleted,
              name: checklist.contentfulName,
            }),
          ),
        }),
      ),
    };
    // Post the reshaped object to the DB
    try {
      await fetch(
        'https://aop-functionapp-dev.azurewebsites.net/api/aop-function-dev',
        { body: JSON.stringify(reshapedUserProgressObject), method: 'POST' },
      );
    } catch (error) {
      console.error('Error posting data:', error);
    }
  }

  // Click handler to pass to checklist components that will update DB and state
  const updateUserChecklistProgress = React.useCallback(
    (itemStatus: boolean, checkboxID: string, checklistID: string) => {
      const currentProfile = profileRef.current;

      // Don't allow updates until initialized with profile data
      if (!currentProfile?.id || !isInitialized) {
        console.log(
          'Checklist update blocked - waiting for profile initialization',
        );
        return;
      }

      const hashedUserId = sha256(currentProfile.id);
      // console.log('Checklist update - Current profile:', {
      //   profileId: currentProfile.id,
      //   hashedId: hashedUserId,
      //   isFallback: currentProfile.isFallback,
      //   isInitialized,
      // });

      setUserProgressState((currentState: UserProgressState) => {
        // console.log('Checklist update - Using current state:', {
        //   state: currentState,
        //   isFallback: currentProfile.isFallback,
        // });

        // Get index of clicked checklist
        const checklistIndex = currentState.checklists.findIndex(
          (checklist: Checklist) => checklist.contentful_id === checklistID,
        );
        // Get index of clicked checklist item
        const itemIndex = currentState.checklists[
          checklistIndex
        ].checklistItems.findIndex(
          (checklistItem: ChecklistItem) =>
            checklistItem.contentful_id === checkboxID,
        );

        // Create a temporary object to hold updated checklist
        const updatedChecklistItem = {
          ...currentState.checklists[checklistIndex].checklistItems[itemIndex],
          isCompleted: itemStatus,
        };

        const updatedChecklist = {
          ...currentState.checklists[checklistIndex],
          checklistItems: [
            ...currentState.checklists[checklistIndex].checklistItems.slice(
              0,
              itemIndex,
            ),
            updatedChecklistItem,
            ...currentState.checklists[checklistIndex].checklistItems.slice(
              itemIndex + 1,
            ),
          ],
        };

        // Create updated user object
        const updatedUserProgressObject = {
          ...currentState,
          userId: hashedUserId, // Ensure we set the correct ID
          checklists: [
            ...currentState.checklists.slice(0, checklistIndex),
            updatedChecklist,
            ...currentState.checklists.slice(checklistIndex + 1),
          ],
        };

        // Update checklist completion status
        if (
          updatedChecklist.checklistItems.every(
            (checklistItem: ChecklistItem) => checklistItem.isCompleted,
          )
        ) {
          if (
            updatedUserProgressObject.checklists[checklistIndex]
              .celebratoryStatusAchieved === false
          ) {
            // If a checklist is completed and a celebration has not yet been acheived, trigger a toast
            // Note: once a celebration has been achieved, it will be locked to "true" in the DB and never update again
            toast(
              <CustomToast
                toastType={'celebratory'}
                userName={currentProfile.givenName}
              />,
              toastOptions('celebratory'),
            );
            updatedUserProgressObject.checklists[
              checklistIndex
            ].celebratoryStatusAchieved = true;
          }
          updatedUserProgressObject.checklists[checklistIndex].isCompleted =
            true;
        } else {
          updatedUserProgressObject.checklists[checklistIndex].isCompleted =
            false;
        }

        // Post the update
        postData(updatedUserProgressObject);

        return updatedUserProgressObject;
      });
    },
    [isInitialized],
  );

  // Click handler to pass to first use onboarding that will update DB and state
  const updateUserFlowStep = (newFlowStep: number) => {
    const currentProfile = profileRef.current;
    if (!currentProfile?.id) return;
    const hashedUserId = sha256(currentProfile.id);
    setUserProgressState((currentState: UserProgressState) => {
      const updatedUserProgressObject = {
        ...currentState,
        userId: hashedUserId,
        flowStep: newFlowStep,
      };
      postData(updatedUserProgressObject);
      return updatedUserProgressObject;
    });
  };

  // Return the context provider with the user object and updater function (placeholder)
  return (
    <UserProgressContext.Provider
      value={{
        userProgressState,
        isInitialized,
        updateUserChecklistProgress,
        updateUserFlowStep,
      }}
    >
      {children}
    </UserProgressContext.Provider>
  );
};

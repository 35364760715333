import { useStaticQuery, graphql } from 'gatsby';

export const useFuoData = () => {
  const response = useStaticQuery(graphql`
    query AllOnboardingSteps {
      allContentfulWelcomePage {
        nodes {
          contentfulName
          contentful_id
          steps {
            contentfulName
            contentful_id
          }
        }
      }
    }
  `);
  return response.allContentfulWelcomePage.nodes;
};

export const onboardingStepCount = () => {
  const onboardingStepsData = useFuoData();
  const stepCount = onboardingStepsData[0].steps.length;
  return stepCount;
};
